/**
 * TODO: GET list of users with relations to other users as auxiliares
 * GET list of hortas nas escolas as well *
 */

import { Button, ButtonGroup, MenuItem } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-flexbox-grid";
import { FaCalendarPlus } from "react-icons/fa";
import { toastr } from "react-redux-toastr";
import MainButton from "../../components/Buttons/MainButton";
import { default as TrainingTable } from "../../components/Table/Training";
import Select from "../../components/form-wrappers/Select";
import { apiAddress, growerTypes, modules } from "../../config/general";
import { store } from "../../store/configure";
import JoditEditor from "jodit-react";

import {
  Input,
  ModalInner,
  OptionWrapElements,
  SideBarPaper,
  StyledLineButton,
  StyledMainButton,
} from "./styled";

/**
 * Component to show trainig modal
 *
 *
 */

const ShowTrainingModal = ({
  showFormationModal,
  setShowFormationModal,
  getTrainings,
  selectedUsers,
  data,
}) => {
  const [toggleSendEmail, setToggleSendEmail] = useState(false);
  const [trainingDetails, setTrainingDetails] = useState(
    data || {
      name: "",
      dates: [{ seats: 1, date: moment().format("YYYY-MM-DDTHH:mm") }],
      location: "",
      type: "0",
      module: "",
      details: "",
      status: 1,
    }
  );

  useEffect(() => {
    setTrainingDetails(data || {});
  }, [data]);

  /**
   * Set training data
   */
  const setTraining = async () => {
    try {
      if (trainingDetails.name === "" || trainingDetails.type === "")
        return toastr.error("Erro", "Preencha todos os campos");
      await axios.post(
        `${apiAddress}api/v1/training${
          showFormationModal && showFormationModal !== true
            ? `/${showFormationModal}`
            : ""
        }`,
        trainingDetails
      );
      toastr.success("Sucesso", "Formação adicionada com sucesso");
      setShowFormationModal(false);
      getTrainings();
    } catch (error) {
      toastr.error("Erro", "Erro ao adicionar formação");
    }
  };

  /**
   * Send email to user
   */
  const sendInvitationEmail = async () => {
    try {
      if (!data.id) throw new Error("no id found");
      await axios.get(`${apiAddress}api/v1/training/sendemail/${data.id}`);
      toastr.success("Sucesso", "Convite para formação enviado com sucesso");
      setShowFormationModal(false);
      getTrainings();
    } catch (error) {
      toastr.error("Erro", "Erro ao enviar email formação");
    }
  };

  /**
   * render content
   */
  return (
    <>
      <Modal
        disableEnforceFocus
        open={showFormationModal}
        onClose={() => setShowFormationModal(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ModalInner>
          <div>
            {toggleSendEmail && (
              <div style={{ textAlign: "center" }}>
                <h2
                  id="simple-modal-title"
                  style={{ textAlign: "center", marginBottom: "1em" }}
                >
                  <span className="icons icon-note" /> Enviar convite de
                  formação
                </h2>

                <div>
                  Está prestes a enviar o email de convite para a formação a
                  todos os utilizadores que estão no estado de formação, tem a
                  certeza que pretende continuar?
                </div>
                <StyledMainButton
                  onClick={async () => await sendInvitationEmail()}
                >
                  <span className="icons icon-envelope" /> Enviar email a{" "}
                  {selectedUsers?.filter((u) => u.status === 11)?.length}{" "}
                  utilizadores
                </StyledMainButton>
                <a
                  onClick={() => setToggleSendEmail(false)}
                  style={{
                    marginTop: "1em",
                    display: "block",
                    cursor: "pointer",
                  }}
                >
                  Cancelar e voltar atrás
                </a>
              </div>
            )}
            {!toggleSendEmail && (
              <>
                <h2
                  id="simple-modal-title"
                  style={{ textAlign: "center", marginBottom: "1em" }}
                >
                  <span className="icons icon-note" />{" "}
                  {showFormationModal && showFormationModal !== true
                    ? "Editar"
                    : "Adicionar"}{" "}
                </h2>

                <Input
                  label="Nome"
                  onChange={(e) =>
                    setTrainingDetails({
                      ...trainingDetails,
                      name: e.target.value,
                    })
                  }
                  value={trainingDetails.name}
                  type="text"
                  meta={{ invalid: false }}
                />

                <Select
                  fullWidth
                  name={"Tipo"}
                  label={"Tipo de Conteúdo"}
                  style={{ marginBottom: ".8em" }}
                  value={trainingDetails.type}
                  onChange={(e) =>
                    setTrainingDetails({
                      ...trainingDetails,
                      type: e.target.value,
                    })
                  }
                  inputProps={{
                    name: "type",
                    id: "type",
                  }}
                >
                  <MenuItem value={`0`}>Formação</MenuItem>
                  <MenuItem value={`1`}>E-mail - Atribuido Pendente</MenuItem>
                  <MenuItem value={`2`}>E-mail - Convite aos Diplomas</MenuItem>
                  <MenuItem value={`3`}>
                    E-mail - Convite para formação
                  </MenuItem>
                  <MenuItem value={`4`}>Área Pessoal</MenuItem>
                  {/* {growerTypes.map((type, i) => {
                    return (
                      <MenuItem key={i} value={`${i}`}>
                        {type}
                      </MenuItem>
                    );
                  })} */}
                </Select>

                {(trainingDetails.type === "0" ||
                  trainingDetails.type === 0) && (
                  <div style={{ width: "100%" }}>
                    <div style={{ width: "100%" }}>
                      {trainingDetails?.dates?.map((d, i) => (
                        <div
                          className="flexwrap"
                          style={{ margin: "1em 0", width: "100%" }}
                        >
                          <Input
                            label="Data"
                            onChange={(e) =>
                              setTrainingDetails({
                                ...trainingDetails,
                                dates: trainingDetails.dates.map((d, a) => {
                                  if (a === i) {
                                    return { ...d, date: e.target.value };
                                  }
                                  return d;
                                }),
                              })
                            }
                            nomargin
                            value={d.date}
                            meta={{ invalid: false }}
                            type="datetime-local"
                          />
                          {/* <Input
                      label="Lugares"
                      onChange={(e) =>
                        setTrainingDetails({
                          ...trainingDetails,
                          dates: trainingDetails.dates.map((d, a) => {
                            if (a === i) {
                              return { ...d, seats: e.target.value };
                            }
                            return d;
                          }),
                        })
                      }
                      nomargin
                      value={d.seats}
                      meta={{ invalid: false }}
                      type="number"
                    /> */}
                          <Select
                            fullWidth
                            name={"Modulo"}
                            label={"Modulo"}
                            style={{ marginBottom: ".8em" }}
                            value={d.module}
                            onChange={(e) =>
                              setTrainingDetails({
                                ...trainingDetails,
                                dates: trainingDetails.dates.map((d, a) => {
                                  if (a === i) {
                                    return { ...d, module: e.target.value };
                                  }
                                  return d;
                                }),
                              })
                            }
                            inputProps={{
                              name: "Modulo",
                              id: "Modulo",
                            }}
                          >
                            <MenuItem value={""}>Escolha</MenuItem>
                            {modules.map((type, i) => {
                              return (
                                <MenuItem key={i} value={`${i}`}>
                                  {type}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          <StyledLineButton
                            style={{
                              margin: 0,
                              padding: 0,
                              lineHeight: 1,
                              height: "40px",
                            }}
                            onClick={() => {
                              setTrainingDetails({
                                ...trainingDetails,
                                dates: trainingDetails.dates.filter(
                                  (date, a) => a !== i
                                ),
                              });
                            }}
                          >
                            X
                          </StyledLineButton>
                        </div>
                      ))}
                    </div>
                    <StyledLineButton
                      style={{
                        margin: ".5em 0 2em 0",
                        padding: "0em 0",
                        width: "100%",
                      }}
                      onClick={() => {
                        setTrainingDetails({
                          ...trainingDetails,
                          dates: [
                            ...(trainingDetails?.dates || []),
                            {
                              seats: 1,
                              date: moment().format("YYYY-MM-DDTHH:mm"),
                            },
                          ],
                        });
                      }}
                    >
                      <span className="icons icon-calendar" /> Adicionar Nova
                      Data
                    </StyledLineButton>
                  </div>
                )}
                {(trainingDetails.type === "0" ||
                  trainingDetails.type === 0) && (
                  <Input
                    label="Local"
                    onChange={(e) =>
                      setTrainingDetails({
                        ...trainingDetails,
                        location: e.target.value,
                      })
                    }
                    value={trainingDetails.location}
                    type="text"
                    meta={{ invalid: false }}
                  />
                )}
                <div
                  style={{
                    fontSize: ".8em",
                    color: "#4da1ff",
                    marginLeft: "1em",
                  }}
                >
                  Texto
                </div>
                {/* <div
                  style={{
                    padding: "1em",
                    border: "1px solid #e9eff4",
                    borderRadius: "5px",
                    marginBottom: "1em",
                  }}
                  contentEditable="true"
                  onBlur={(e) =>
                    setTrainingDetails({
                      ...trainingDetails,
                      details: e.currentTarget.innerHTML,
                    })
                  }
                  dangerouslySetInnerHTML={{
                    __html:
                      trainingDetails.details ||
                      "Coloque aqui algum texto sobre a formação ou apague este texto.",
                  }}
                /> */}
                <JoditEditor
                  value={trainingDetails.details}
                  tabIndex={1} // tabIndex of textarea
                  onBlur={(e) =>
                    setTrainingDetails({
                      ...trainingDetails,
                      details: e,
                    })
                  }
                />
                <br />
                <Select
                  fullWidth
                  name={"status"}
                  label={"Estado"}
                  value={trainingDetails.status}
                  onChange={(e) =>
                    setTrainingDetails({
                      ...trainingDetails,
                      status: e.target.value,
                    })
                  }
                  style={{ marginBottom: ".8em" }}
                  inputProps={{
                    name: "status",
                    id: "status",
                  }}
                >
                  <MenuItem value={0}>Inativo</MenuItem>
                  <MenuItem value={1}>Ativo</MenuItem>
                  <MenuItem value={2}>Finalizado</MenuItem>
                  <MenuItem value={3}>Eleminado</MenuItem>
                </Select>
                {trainingDetails.type === "0" && (
                  <ButtonGroup style={{ width: "100%", fontSize: ".8em" }}>
                    <Button
                      style={{ fontSize: ".9em", width: "50%" }}
                      onClick={async () => {
                        // Copy the text inside the text field
                        navigator?.clipboard?.writeText(
                          `https://areapessoal.terrasdecascais.pt/training/${trainingDetails?.id}`
                        );
                      }}
                    >
                      <span
                        className="icons icon-link"
                        style={{ marginRight: ".8em" }}
                      />{" "}
                      Copiar link
                    </Button>
                    <Button
                      style={{ fontSize: ".9em", width: "50%" }}
                      onClick={async () => setToggleSendEmail(true)}
                    >
                      <span
                        className="icons icon-envelope"
                        style={{ marginRight: ".8em" }}
                      />{" "}
                      Enviar email de convite
                    </Button>
                  </ButtonGroup>
                )}
                <StyledMainButton onClick={async () => await setTraining()}>
                  <span className="icons icon-note" /> Salvar
                </StyledMainButton>
              </>
            )}
          </div>
        </ModalInner>
      </Modal>
    </>
  );
};

/**
 * Render compoenent training
 * @param {*} props
 * @returns
 */
const Training = (props) => {
  const [selectedUsers, setSelectedUsers] = useState(props.selectedUsers || []);

  const [trainings, setTrainings] = useState([]);
  const [showFormationModal, setShowFormationModal] = useState(false);
  const [selectedTraining, setSelectedTraining] = useState(false);
  const [selectedDate, setSelectedDate] = useState(false);

  const parks = store.getState().parklist.list;
  useEffect(() => {
    // props.getUserSuplentes();
    getTrainings();
    props.getUserSuplentes();
    return () => {};
  }, []);

  // useEffect(() => {
  //   return () => {};
  // }, []);

  useEffect(() => {
    setSelectedUsers(props.selectedUsers);
    return () => {};
  }, [props.selectedUsers]);

  const getTrainings = async () => {
    try {
      //get selected user type
      const thisTypeId = growerTypes.indexOf(props.selectedTypePark);
      const { data } = await axios.get(
        `${apiAddress}api/v1/training/${thisTypeId}/all`
      );
      setTrainings(data.msg || []);
    } catch (error) {
      toastr.error("Erro", "Erro ao obter formação");
    }
  };

  /** filter users by props pid */
  const processSelectedPark = () => {
    //function to check if user is in park
    const checkIfUserIsInTraining = (user) => {
      //check if we have training filter one
      let isInTraining = true;
      if (selectedTraining) {
        isInTraining = false;
        isInTraining = user?.training?.find((t) => {
          // console.log(`${t.TrainingId}`, `${selectedTraining}`);
          return `${t.TrainingId}` === `${selectedTraining}`;
        });
      }
      return isInTraining;
    };

    // getUserSuplentes();
    if (props.pid && props.pid !== "all") {
      return selectedUsers.filter((user) => {
        //check if we have training filter one
        let isInTraining = checkIfUserIsInTraining(user);
        //check if its the corrct user type
        if (growerTypes[user.type] === props.selectedTypePark) {
          const userParks = Object.keys(user.ranks);
          // return isInTraining && userParks.includes(props.pid);
          if (isInTraining && userParks.includes(props.pid)) {
            return (
              user.ranks[props.pid]?.ranking1?.status === 1 ||
              user.ranks[props.pid]?.ranking2?.status === 1 ||
              user.ranks[props.pid]?.rankingFreguesia?.status === 1
            );
          }

          return false;
        }
        return false;
      });
      // setSelectedUsers(park?.users);
    } else {
      return selectedUsers.filter((user) => {
        let isInTraining = checkIfUserIsInTraining(user);
        return isInTraining;
        // && growerTypes[user.type] === props.selectedTypePark
      });
    }

    return selectedUsers;
  };

  const setUsersToSelectedTraining = async (training, name, contacts) => {
    try {
      const setUserToSelectedDateTraining = async () => {
        try {
          //get selected date from select-date-training
          const selectedDate = document.getElementById(`select-date-training`)
            .value;

          const { data } = await axios.post(
            `${apiAddress}api/v1/training/users/${training.id}`,
            {
              users: contacts,
              date: selectedDate,
              id: training.id,
            }
          );
          if (data.success) {
            toastr.success("Sucesso", "Formação atualizada com sucesso");
            if (document.getElementsByClassName(`close-toastr`)[0]) {
              document.getElementsByClassName(`close-toastr`)[0].click();
            }
            props.getUserSuplentes();
          } else {
            toastr.error("Erro", "Erro ao atualizar formação");
          }
        } catch (error) {
          console.log(error);
          toastr.error("Erro", "Erro ao atualizar formação");
        }
      };
      const toastrConfirmOptions = {
        // onOk: () => setNewAttrState(),
        closeOnShadowClick: false,
        enableEscapeKey: false,
        enableEnterKey: false,
        timeOut: 0, // Default value is 0
        removeOnHover: false, // Default value is false
        removeOnHoverTimeOut: 0, // Default value is 1000
        component: (
          <div>
            <div
              style={{
                lineHeight: "1",
                marginBottom: "1em",
              }}
            >{`Escolha o horário pretendido para a formacao ${name}`}</div>
            <div
              style={{
                display: "flex",
                margin: ".5em 0",
                justifyContent: "flex-end",
              }}
            >
              <select style={{ width: "100%" }} id={"select-date-training"}>
                {training.dates.map((d, index) => (
                  <option
                    value={d.date}

                    // onChange={(e) => setSelectedDate(e.target.value)}
                  >
                    {d.date ? d.date.replace("T", " ") : ""}
                  </option>
                ))}
              </select>
              <br />
              <MainButton
                size={"small"}
                color={"primary"}
                onClick={() => setUserToSelectedDateTraining()}
              >
                Inscrever
              </MainButton>
            </div>
          </div>
        ),
      };

      toastr.message(
        `Adicionar  ${contacts.length} contacto(s) à formação?`,
        toastrConfirmOptions
      );
    } catch (error) {}
  };

  /**
   * Set selected users to training
   * @param {*} selectedRows
   * @param {*} displayData
   * @param {*} setSelectedRows
   * @returns
   *  **/
  const selectCustomAction = (selectedRows, displayData, setSelectedRows) => {
    let contacts = [];
    if (selectedRows && selectedRows.data && selectedRows?.data.length > 0) {
      contacts = selectedRows?.data?.map((row) => {
        const thisData = displayData.find((d) => d.dataIndex === row.dataIndex);
        //left find the user id from row.dataIndex
        return thisData?.data[0];
      });
    }

    // if (selectedRows && selectedRows.data && selectedRows?.data.length > 0) {
    //   contacts = selectedRows?.data?.map(
    //     (row) =>
    //       props?.growers[row.dataIndex] && props?.growers[row.dataIndex]?.id
    //   );
    // }
    console.log(contacts);
    return (
      <div style={{ fontSize: ".8em" }}>
        <ButtonGroup style={{ flexWrap: "wrap" }}>
          {trainings
            .filter((t) => t.status === 1 && t.type === 0)
            .map((t, i) => {
              return (
                <MainButton
                  onClick={() => {
                    setUsersToSelectedTraining(t, t.name, contacts);
                  }}
                >
                  {t.name}
                </MainButton>
              );
            })}{" "}
          {/* <MainButton
            active={"true"}
            onClick={() => {
              setUsersToSelectedTraining(false);
            }}
          >
            Remove
          </MainButton> */}
        </ButtonGroup>
      </div>
    );
  };

  /**
   * Action to update user training status
   * @param {*} training
   * @param {*} user
   * @param {*} status
   * @returns
   * **/

  const updateUserTraining = async (
    training, //usertraining id
    user,
    status,
    trainigId, // training id
    userTraining,
    date
  ) => {
    try {
      if (!training || !user) throw new Error("Missing params");
      const { data } = await axios.post(
        `${apiAddress}api/v1/training/user/${training}/${user}/${status}`,
        {
          date,
          userTraining,
          trainigId,
        }
      );

      // now we need to update userlist
      if (status === "finished" || status === 4) {
        props.getUserSuplentes();
      } else {
        setSelectedUsers(
          selectedUsers.map((u) => {
            if (u.id === user) {
              //lets see if we have the training
              if (!u.training.find((t) => t.id === userTraining)) {
                return {
                  ...u,
                  training: [...u.training, data.data || {}],
                };
              } else {
                return {
                  ...u,
                  training: u.training.map((t) => {
                    if (t.id === userTraining) {
                      return {
                        ...t,
                        status,
                      };
                    }
                    return t;
                  }),
                };
              }
            }
            return u;
          })
        );
      }
    } catch (error) {
      // toastr.error("Aconteceu um obter lista");
      console.log(error);
    }
  };
  /**
   * get users for a park
   */
  // const getUserSuplentes = async () => {
  //   try {
  //     const res = await axios.get(`${apiAddress}api/v1/rankings/status/11,9`);
  //     setSelectedUsers(
  //       res?.data?.msg?.filter((u) => {
  //         //check if its the corrct user type

  //         if (u.status === 7 && Number(u.attrStatus) !== 6) {
  //           return false;
  //         }
  //         return true;
  //       }) || []
  //     );
  //   } catch (error) {
  //     // toastr.error("Aconteceu um obter lista");
  //     console.log(error);
  //   }
  // };

  /***Set actions hooks */
  // const updateUserEducation = async (user, eduction) => {};
  // //update user state
  const updateUserEducation = async (state, user) => {
    //get user from selectedUsers
    const userObj = selectedUsers.find((u) => u.id === user);
    let updatedNote = userObj.note || "";
    let updateStatus = {};

    //if state 3, set user to inactive
    const setNewAttrState = async () => {
      try {
        const res = await axios.post(
          `${apiAddress}api/v1/contacts/education/${user}`,
          updateStatus
        );
        if (document.getElementsByClassName(`close-toastr`)[0]) {
          document.getElementsByClassName(`close-toastr`)[0].click();
        }
        if (state === "finished") {
          await axios.post(`${apiAddress}api/v1/contacts/status/${user}`, {
            status: 9,
            note: updatedNote,
          });
          props.getUserSuplentes();
        } else {
          // now we need to update userlist
          setSelectedUsers(
            selectedUsers.map((u) => {
              if (u.id === user) {
                return {
                  ...u,
                  status: u.status,
                  education: updateStatus.education,
                  educationDone: updateStatus.educationDone,
                };
              }
              return u;
            })
          );
        }
      } catch (error) {
        // toastr.error("Aconteceu um obter lista");
        console.log(error);
      }
    };

    if (state === "finished") {
      updateStatus = { educationDone: true };
    } else {
      //its a module, let check and combine with user previouse modules
      let newModules = [];
      if (userObj.education?.includes(state)) {
        newModules = userObj.education.filter((m) => m !== state);
      } else {
        newModules = [...new Set([...(userObj.education || []), state])];
      }

      updateStatus = { education: newModules };
      setNewAttrState();
    }

    const toastrConfirmOptions = {
      // onOk: () => setNewAttrState(),
      // onCancel: () => console.log("CANCEL: clicked"),
      // closeOnShadowClick: false,
      // enableEscapeKey: false,
      // enableEnterKey: false,
      timeOut: 0, // Default value is 0
      removeOnHover: false, // Default value is false
      removeOnHoverTimeOut: 0, // Default value is 1000
      component: (
        <div>
          <div
            style={{
              fontSize: "1.2em",
              lineHeight: "1",
              fontWeight: "bold",
              marginBottom: "1em",
            }}
          ></div>
          <textarea
            multiline
            id={`note-${user}`}
            name="note"
            defaultValue={updatedNote}
            onChange={(e) => {
              updatedNote = e.target.value;
            }}
            type="multiline"
            rows="7"
            onKe
            style={{
              padding: "0.5em",
              fontFamily: "Arial",
              display: "block",
              width: "100%",
            }}
          />
          <div
            style={{
              display: "flex",
              margin: ".5em 0",
              justifyContent: "flex-end",
            }}
          >
            <MainButton
              size={"small"}
              color={"primary"}
              onClick={() => setNewAttrState()}
            >
              Salvar Alterações
            </MainButton>
          </div>
        </div>
      ),
    };

    if (state === "finished") {
      toastr.message(
        "Adicione um comentário antes de proceder com esta alteração",
        toastrConfirmOptions
      );
    }
  };

  /**
   * Render the component table and sidebar
   */

  const TrainingOverview = ({}) => {
    return (
      <>
        <Col xs={12} sm={3} md={3} lg={3}>
          <SideBarPaper>
            <div style={{ padding: "1em" }}>
              <div style={{ position: "relative", margin: "0 0 2em 0" }}>
                {/* <div
                  style={{
                    textAlign: "left",
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "bold",
                    color: "#28587f",
                    fontSize: "1em",
                    marginBottom: "1em",
                  }}
                >
                  <div
                    style={{
                      display: "inline-block",
                      background: "#28587f",
                      color: "#fff",
                      padding: ".3em",
                      fontSize: "1em",
                      borderRadius: "6px",
                      marginRight: "10px",
                    }}
                  >
                    <FaCalendarAlt />
                  </div>{" "}
                  Formações Ativas
                </div> */}

                {trainings
                  ?.filter((t) => t.status !== 3 && t.status !== 2)
                  .map((t, i) => {
                    return (
                      <OptionWrapElements
                        selected={selectedTraining === t.id}
                        onClick={() => {
                          setSelectedTraining(
                            selectedTraining === t.id ? null : t.id
                          );
                        }}
                      >
                        <div className="details">
                          <div className="name" style={{ fontSize: "1em" }}>
                            {t.name}
                          </div>
                          {/* <div className="name" style={{ fontSize: "1.2em" }}>
                          {modules[t.module]}
                        </div> */}

                          {t.status === 0 && `Inativo`}
                          {t.status === 1 && `Ativo`}
                          {t.status === 2 && `Finalizado`}
                          {t.status === 3 && `Eleminado`}
                          {t?.dates?.length ? (
                            <div style={{ fontSize: ".8em" }}>
                              {t?.dates?.length} data(s)
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        <div
                          className="places"
                          onClick={() => {
                            setShowFormationModal(t.id);
                          }}
                        >
                          <div className="total" style={{ fontSize: "1em" }}>
                            <span className="icons icon-note" />{" "}
                          </div>
                        </div>
                      </OptionWrapElements>
                    );
                  })}

                <div style={{ position: "relative" }}>
                  <StyledMainButton onClick={() => setShowFormationModal(true)}>
                    <FaCalendarPlus /> Adicionar Nova
                  </StyledMainButton>
                </div>
              </div>
            </div>
          </SideBarPaper>
        </Col>
      </>
    );
  };

  return (
    <div>
      <Row>
        <TrainingOverview />
        <ShowTrainingModal
          setShowFormationModal={setShowFormationModal}
          showFormationModal={showFormationModal}
          getTrainings={getTrainings}
          selectedUsers={selectedUsers}
          data={trainings.find((t) => t.id === showFormationModal)}
        />
        <Col xs={12} sm={9} md={9} lg={9}>
          <TrainingTable
            orderBy={"id"}
            selectable={true}
            data={processSelectedPark(selectedUsers)}
            // updateUserState={updateUserState}
            updateUserEducation={updateUserEducation}
            updateUserTraining={updateUserTraining}
            parks={parks}
            trainings={trainings}
            quotas={props.quotas}
            selectCustomAction={selectCustomAction}
            // console.log('Materials', this.props.materials);
            // console.log('isFetching', this.props.isFetching);
            // console.log('error', this.props.error);
            // reloadData={this.props.getMaterials(this.state.search)}
            // click={this.goToDetail}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Training;
